import React from "react";
import { Form } from "antd";
import { FirestoreDocument } from "react-firestore";
import { useParams } from "react-router-dom";

import SubmitButton from "../AppSubmitButton";
import {
  MediaNameForm,
  MediaLogoForm,
  MediaTextLogoForm,
  GAIdForm,
  GradeSelectForm,
  MediaIdForm
} from "./FormItems";

const MediaForm = ({ onFinish }) => {
  const params = useParams();

  const { mediaId } = params;

  return (
    <FirestoreDocument
      path={`media/${mediaId}`}
      render={({ isLoading, data, snapshot, error }) => {
        return isLoading || error ? null : (
          <Form
            name="basic"
            layout="vertical"
            initialValues={data}
            onFinish={onFinish}
          >
            <MediaIdForm disabled={!!mediaId} />
            <MediaNameForm />
            <MediaLogoForm />
            <MediaTextLogoForm />
            <GAIdForm />
            <GradeSelectForm />
            <SubmitButton />
          </Form>
        );
      }}
    />
  );
};

export default MediaForm;
