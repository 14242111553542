import React from "react";
import { Drawer, Form, Button, Input } from "antd";

import SubmitButton from "../AppSubmitButton";

const FormItem = Form.Item;

const NewMemberForm = ({ onClose, onFinish, visible }) => {
  const [form] = Form.useForm();

  return (
    <Drawer
      title="招待するメールアドレスを入力してください"
      width={480}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={visible}
    >
      <Form form={form} onFinish={onFinish}>
        <FormItem
          label="メールアドレス"
          name="mail"
          rules={[{ required: true, message: "Input User Email Address",
            pattern: new RegExp(
              /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            ) }]}
        >
          <Input placeholder="Input User Email Address" />
        </FormItem>
        <div
          style={{
            width: "100%",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <SubmitButton />
        </div>
      </Form>
    </Drawer>
  );
};

export default NewMemberForm;
