import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Skeleton } from "antd";

import AppTable from "../AppTable";
import { formatDate } from "../../modules/utils";

const columns = [
  {
    title: "User",
    dataIndex: "user"
  },
  {
    title: "作成日",
    dataIndex: "createdAt",
    render: (createdAt) =>
    createdAt && <div>{formatDate(createdAt.toDate())}</div>,
  },
];

const StoryCollection = ({ mediaId }) => {
  return (
    <FirestoreCollection
      path={`media/${mediaId}/_members`}
      sort="createdAt:desc"
      render={({ isLoading, data, snapshot, error }) => (
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <AppTable data={data} columns={columns} />
          )}
        </div>
      )}
    />
  );
};

export default StoryCollection;
