import React, { FunctionComponent } from 'react';
import { Form, Button } from 'antd';

const FormItem = Form.Item;

const MediaForm: FunctionComponent = () => (
  <FormItem style={{ textAlign: 'right' }}>
    <Button type="primary" htmlType="submit" size="large">
      保存する
    </Button>
  </FormItem>
)

export default MediaForm;
