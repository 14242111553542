import React from "react";
import { Form, Input, Select } from "antd";

import TooltipButton from "../TooltipButton";

const FormItem = Form.Item;
const { Option } = Select;

const MediaIdForm = ({ disabled }) => (
  <FormItem
    label={
      <>
        <span style={{ marginRight: 12 }}>メディアID（変更不可）</span>
        <TooltipButton text="anymindgroup, bunshun, autosport, presidentなどわかりやすく設定してください" />
      </>
    }
    name="identifierId"
    rules={[
      {
        required: true,
        pattern: new RegExp(
          /^[0-9a-zA-Z]*$/
        )
      },
    ]}
  >
    <Input placeholder="Media ID" disabled={disabled} />
  </FormItem>
);

const MediaNameForm = () => (
  <FormItem
    label="メディア名"
    name="name"
    rules={[{ required: true, message: "Please input Media name" }]}
  >
    <Input placeholder="Media Name" />
  </FormItem>
);

const MediaLogoForm = () => (
  <FormItem
    label={
      <>
        <span style={{ marginRight: 12 }}>メディアロゴ</span>
        <TooltipButton text="メディアのロゴURLを入力してください" />
      </>
    }
    name="logo"
    rules={[{ required: true, message: "Please unput your Media Logo URL" }]}
  >
    <Input placeholder="https://www.google.com/s2/favicons?domain=blog.ohoshi.me" />
  </FormItem>
);

const MediaTextLogoForm = () => (
  <FormItem
    label={
      <>
        <span style={{ marginRight: 12 }}>メディアテキストロゴ</span>
        <TooltipButton text="横長のロゴURLを入力してください" />
      </>
    }
    name="textLogo"
    rules={[{ required: true }]}
  >
    <Input placeholder="https://blog.ohoshi.me/static/ohoshi-logo.png" />
  </FormItem>
);

const GAIdForm = () => (
  <FormItem
    label={
      <>
        <span style={{ marginRight: 12 }}>Google Analytics ID</span>
        <TooltipButton text="Google Analytics IDを入力してください" />
      </>
    }
    name="analyticsId"
    rules={[{ pattern: new RegExp(/(UA|YT|MO)-\d+-\d+/) }]}
  >
    <Input placeholder="UA-1234-12" />
  </FormItem>
);

const GradeSelectForm = () => (
  <FormItem
    label={
      <>
        <span style={{ marginRight: 12 }}>プラン</span>
        <TooltipButton text="※最初は必ずFreeを選択してください" />
      </>
    }
    name="grade"
  >
    <Select defaultValue="free" style={{ width: 120 }}>
      <Option value="free">Free</Option>
      <Option value="standard">Standard</Option>
      <Option value="premium">Premium</Option>
    </Select>
  </FormItem>
);

const AdUnitPathForm = () => (
  <FormItem
    label="Adx広告ユニット"
    name="adUnitPath"
    rules={[{ pattern: new RegExp(/^\/\d+(\/[\w-\.\*\\\!\?<>:\(\)]+)+$/) }]}
  >
    <Input placeholder="/83555300/fourmtagservice-test/in-article" style={{ width: '60%' }} />
  </FormItem>
);

export {
  MediaIdForm,
  MediaNameForm,
  MediaLogoForm,
  MediaTextLogoForm,
  GAIdForm,
  GradeSelectForm,
  AdUnitPathForm,
};
