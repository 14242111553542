import React from 'react';
import ReactLoading from 'react-loading';

const root = `
  .root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(140, 50, 255);
  }
`

const LoadingScreen = props => {

  return (
    <div className="root">
      <ReactLoading type="balls" height={'110px'} width={'110px'} />
      <style jsx>{root}</style>
    </div>
  );
};

export default LoadingScreen;
