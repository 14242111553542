import React, { useState } from "react";
import { FirestoreDocument } from "react-firestore";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Switch, Divider } from "antd";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai";

import { AdUnitPathForm } from "./FormItems";
import TemplateVisualSelector from "./TemplateVisualSelector";

const { Item: FormItem } = Form;
const { TextArea } = Input;

const style = `
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .name {
    border: none;
    font-size: 24px!important;
    font-weight: 600;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }
  .title-form {
    width: 75%;
  }
  .title-form label {
    padding-top: 24px;
  }
  .name::focus {
    border: none;
    border-bottom: 1px solid #8b32ff;
  }
  .preference-form > .title {
    margin-bottom: 24px;
    font-size: 20px;
  }
  .title-form .ant-input:focus {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #40a9ff;
  }

  .preference-form > .forms {
    margin-left: 24px;
  }
  .preference-form .ant-form-item-label {
    width: 20%;
    text-align: left;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .divider {
    margin: 36px 0!important;
  }
  .page-add-button {
    position: absolute;
    right: 20%;
  }
`;

const CssEditor = ({ initialValue }) => (
  <FormItem label="カスタムCSS" name="overrideCss" initialValue={initialValue}>
    <AceEditor width="80%" height="400px" mode="css" theme="monokai" />
  </FormItem>
);

const Url = () => (
  <FormItem
    label="記事URL"
    name="textUrls"
    rules={[
      {
        validator: async (_, textUrls) => {
          if (!textUrls) {
            return Promise.reject(new Error("Storyにしたい記事のURLを改行区切りで入力してください"));
          } else {
            const rule = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
            const urls = textUrls.split('\n');
            const isValid = urls.every(url => url.match(rule));
            if (!isValid) {
              return Promise.reject(new Error("改行区切りでURLを入力してください"));
            }
          }
        },
        required: true
      },
    ]}
  >
    <TextArea
      placeholder="記事のURLを改行区切りで入力してください"
      autoSize={{ minRows: 3, maxRows: 15 }}
    />
  </FormItem>
);

const StoryNameForm = ({ initialValue }) => (
  <FormItem
    label="タイトル"
    name="name"
    initialValue={initialValue}
    rules={[{ required: true, message: "Please input Story name" }]}
    className="title-form"
  >
    <Input placeholder="Input Your Media Name" className="name" />
  </FormItem>
);

const SupportLandscapeSwitchForm = ({ initialValue }) => (
  <FormItem
    label="PC時全画面表示にする"
    name="isSupportLandscape"
    valuePropName="unchecked"
  >
    <Switch defaultChecked={initialValue.isSupportLandscape || false} />
  </FormItem>
);

const AutoPagingSwitchForm = ({ initialValue }) => (
  <FormItem
    label="自動でページ遷移する"
    name="isAutoPaging"
    valuePropName="unchecked"
  >
    <Switch defaultChecked={initialValue.isAutoPaging || false} />
  </FormItem>
);

const SubmitButton = ({ isValid }) => (
  <FormItem>
    <Button
      type="primary"
      htmlType="submit"
      disabled={!isValid}
      size="large"
      style={{ position: "absolute", right: 240 }}
    >
      保存する
    </Button>
  </FormItem>
);

const Select = ({ mediaId, initialValue, ...props }) => {
  const handleChange = (value) => {
  };
  return (
    <FormItem label="デザイン" name="template">
      <TemplateVisualSelector
        mediaId={mediaId}
        defaultValue={initialValue.template}
        value={initialValue}
        onChange={handleChange}
      />
    </FormItem>
  );
};

const StoryForm = ({ onFinish, isPremium = false }) => {
  const [isValid, updateIsValid] = useState(false);

  const params = useParams();

  const { mediaId, storyId } = params;

  const onFieldsChange = (changedField, allFields) => {
    if (
      allFields.length > 0 &&
      allFields.filter((field) => field["errors"].length > 0).length === 0
    ) {
      updateIsValid(true);
    } else {
      updateIsValid(false);
    }
  };

  return (
    <FirestoreDocument
      path={`media/${mediaId}/story/${storyId}`}
      render={({ isLoading, data, snapshot, error }) => {
        return isLoading || error ? null : (
          <Form
            name="dynamic_form_item"
            onFinish={onFinish}
            layout="vertical"
            initialValues={data}
            onFieldsChange={onFieldsChange}
          >
            <StoryNameForm />
            <Url />
            <Divider plain orientation="left" className="divider">
              詳細な設定
            </Divider>
            <div className="preference-form">
              <div className="forms">
                <span>
                  <SupportLandscapeSwitchForm initialValue={data} />
                  <AutoPagingSwitchForm initialValue={data} />
                  {isPremium && <AdUnitPathForm initialValue={data} />}
                </span>
              </div>
            </div>
            <Divider plain orientation="left" className="divider">
              Design
            </Divider>
            <div className="preference-form">
              <div className="forms">
                <Select mediaId={mediaId} initialValue={data} />
                <CssEditor />
              </div>
            </div>
            <SubmitButton isValid={isValid} />
            <style jsx>{style}</style>
          </Form>
        );
      }}
    />
  );
};

export default StoryForm;
