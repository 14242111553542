import React, { useState, FunctionComponent } from 'react';
import { Tabs, Typography, Divider, Button } from 'antd';
import * as H from 'history'
import { useParams } from 'react-router-dom';

import MediaForm from '../components/form/MediaForm';
import MemberCollection from '../components/firestore/MemberCollection';
import MemberInvitationCollection from '../components/firestore/MemberInvitationCollection';
import NewMemberForm from '../components/form/NewMemberForm';
import { withNotification } from '../modules/hoc/NotificationProvider';
import { firestore, firestoreServerTimestamp } from "../modules/firebase-helper";
import { PathParams } from '../models';

const { TabPane } = Tabs;
const { Title } = Typography;

const appContainer = `
  .app-container {
    height: 88vh;
    padding: 24px;
    margin: 40px;
    overflow: scroll;
    background-color: #fff;
  }
  .amp-story-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .amp-story-preview {
    width: 80%;
    max-width: 360px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit- transform: translateY(-50%) translateX(-50%);
  },
  .divider {
    margin: 12px 0;
  }
  .member-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
`;

type MediaEditType = {
  mediaId: String;
  history: H.History;
  showNotification: Function;
}

const MediaEdit: FunctionComponent<MediaEditType> = ({
  history,
  showNotification
}: MediaEditType) => {
  const params: PathParams = useParams();
  const { mediaId } = params;
  const [drawerVisible, setDrawerVisible] = useState(false);

  
  const onMemberInvitationSubmit = async (values: any) => {
    const { mail } = values;
    const doc = firestore.collection("mediaInvitations").doc(`${mediaId}__${mail}`);
    await doc.set({
      mail: mail,
      mediaId: mediaId,
      createdAt: firestoreServerTimestamp(),
      updatedAt: firestoreServerTimestamp()
    })

    setDrawerVisible(false)
  };

  const onFinish = async (value: any) => {
    const { identifierId, name, logo, textLogo, analyticsId = '', grade = 'free' } = value;

    if (mediaId) {
      await firestore.doc(`media/${mediaId}`).update({
        name: name,
        logo: logo,
        textLogo: textLogo,
        analyticsId: analyticsId,
        grade: grade,
        identifierId: identifierId,
        updatedAt: firestoreServerTimestamp(),
      }).then(() => {
        showNotification('Success update');
      })
    } else {
      await firestore.collection('media').doc(identifierId).set({
        name: name,
        logo: logo,
        textLogo: textLogo,
        analyticsId: analyticsId,
        grade: grade,
        identifierId: identifierId,
        createdAt: firestoreServerTimestamp(),
        updatedAt: firestoreServerTimestamp(),
      }).then(() => {
        history.goBack();
        showNotification('Success create');
      });
    }
  }

  return (
    <div className="app-container">
      <Tabs type="card">
        <TabPane tab="Settings" key="1">
          <MediaForm onFinish={onFinish} />
        </TabPane>
        <TabPane tab="Members" key="2">
          <div className="member-header">
            <Title level={3} style={{ marginLeft: 12 }}>
              ユーザー
            </Title>
            <Button
              type="primary"
              size="large"
              onClick={() => setDrawerVisible(true)}
            >
              ユーザーを追加する
            </Button>
          </div>
          <MemberCollection mediaId={mediaId} />
          <Divider plain orientation="left" className="divider" />
          <Title level={3} style={{ marginLeft: 12 }}>
            招待中のユーザー
          </Title>
          <MemberInvitationCollection mediaId={mediaId} />
        </TabPane>
      </Tabs>

      <NewMemberForm
        visible={drawerVisible}
        onFinish={onMemberInvitationSubmit}
        onClose={() => {
          setDrawerVisible(false)}
      }
      />
      <style jsx>{appContainer}</style>
    </div>
  )
}

export default withNotification(MediaEdit);
