import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import LoadingScreen from './LoadingScreen';

const LazyRoute = ({ page, ...rest }) => {
  const Component = lazy(() => import(`pages/${page}`));

  const LazyComponent = props => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

  return <Route component={LazyComponent} {...rest} />;
};

export default LazyRoute;
