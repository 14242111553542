import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Link } from "react-router-dom";
import { Skeleton, Space, Button, Card, Typography, Row, Col } from "antd";
import { BookOutlined, SettingOutlined } from "@ant-design/icons";

import { withAuth } from '../../modules/hoc/AuthProvider';

const { Meta } = Card;
const { Text } = Typography;

const style = `
  .batch {
    padding: 0 10px;
    height: 24px;
    border-radius: 24px;
  }
  .free {
    color: #999;
    border: solid 1px #999;
  }
  .success {
    background-color: rgb(82, 196, 26);
  }
  .premium {
    background-color: #1890ff;
  }

  .success span {
    color: #fff;
  }

  .premium span {
    color: #fff;
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gutter-row {
    width: 95%;
    margin: 0 auto!important;
  }
  .col {
    padding: 16px;
  }
  .media-card {
    min-width: 260px;
    max-width: 400px;
  }
  .media-card li {
    margin: 0;
  }
  .cover-image {
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    background-size: contain;
    background-color: #fafafa;
  }
  .action-button {
    width: 100%;
    padding: 8px 0;
    height: 100%;
  }
  .counter {
    text-align: right;
  }
`;

const gradePairs = {
  free: {
    text: "Free",
  },
  standard: {
    text: "Standard",
  },
  premium: {
    text: "Premium",
  },
};

const Grade = ({ grade }) => (
  <Space className={`batch ${grade}`}>
    <Text>{gradePairs[grade]["text"]}</Text>
  </Space>
);

const MediaCollection = ({
  currentUser
}) => {
  const { roles = [], media = ['none']} = currentUser;
  const _filter = roles.indexOf('admin') > -1 ? {} : { filter: [['identifierId', 'in', media]] };

  return (
    <FirestoreCollection
      path={`media`}
      {..._filter}
      // sort="createdAt:desc"
      render={({ isLoading, data }) => (
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="gutter-row"
            >
              {data.map((item) => (
                <Col className="col">
                  <Card
                    style={{
                      backgroundImage: `${item["textLogo"]}`,
                    }}
                    className="media-card"
                    cover={
                      <div
                        className="cover-image"
                        style={{
                          backgroundImage: `url(${item["textLogo"]})`,
                        }}
                      />
                    }
                    actions={[
                      <Link to={`/dashboard/media/${item.id}/story`}>
                        <Button
                          type="text"
                          icon={<BookOutlined />}
                          className="action-button"
                        >
                          Stories
                        </Button>
                      </Link>,
                      <Link to={`/dashboard/media/${item.id}/edit`}>
                        <Button
                          type="text"
                          icon={<SettingOutlined />}
                          className="action-button"
                        >
                          設定
                        </Button>
                      </Link>,
                    ]}
                  >
                    <Meta
                      title={item["name"]}
                      description={
                        <div className="description">
                          <div>
                            <div>Stories数</div>
                            <div className="counter">{item["count"] || 0}</div>
                          </div>
                          <Grade grade={item["grade"]} />
                        </div>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          <style jsx>{style}</style>
        </div>
      )}
    />
  );
};

export default withAuth(MediaCollection);
