import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Skeleton, Typography, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import AppTable from "../AppTable";
import { formatDate } from "../../modules/utils";
import { withNotification } from "../../modules/hoc/NotificationProvider";

const { Text } = Typography;

const styles = `
  .url {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    padding: 4px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .url > span {
    margin-right: 12px;
  }
  .url::-webkit-scrollbar {
    display: none;
  }
`;

const columns = [
  {
    title: "User",
    dataIndex: "mail",
  },
  {
    title: "作成日",
    dataIndex: "createdAt",
    render: (createdAt) =>
      createdAt && <div>{formatDate(createdAt.toDate())}</div>,
  },
  {
    title: "招待URL",
    dataIndex: "mediaId",
    render: (mediaId) => mediaId && <ClipBoard url={`${process.env.REACT_APP_BASE_URL}/createUser?projectId=${mediaId}`} />,
  },
];

const ClipBoard = withNotification(({ url, showNotification }) => {
  return (
    <>
      <Text className="url" type="secondary">
        <span>{url}</span>
        <CopyToClipboard text={url} onCopy={() => showNotification("Copied")}>
          <Button>Copy</Button>
        </CopyToClipboard>
      </Text>
    </>
  );
});

const StoryCollection = ({ mediaId }) => {
  return (
    <FirestoreCollection
      path="mediaInvitations"
      filter={["mediaId", "==", mediaId]}
      render={({ isLoading, data, snapshot, error }) => (
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <AppTable data={data} columns={columns} />
          )}
          <style jsx>{styles}</style>
        </div>
      )}
    />
  );
};

export default StoryCollection;
