import React, { createContext } from "react";
import { notification } from "antd";

const Context = createContext();

export const withNotification = (WrappedComponent) => (props) => {
  const [api, contextHolder] = notification.useNotification();

  const showNotification = (message = 'Message') => {
    api.info({
      message: (
        <Context.Consumer>{() => message}</Context.Consumer>
      ),
      placement: "bottomRight",
      duration: 3,
    });
  };

  return (
    <Context.Provider>
      {contextHolder}
      <WrappedComponent {...props} showNotification={showNotification} />
    </Context.Provider>
  );
};
