import "whatwg-fetch";
import qs from "query-string";

import { firebase } from './firebase-helper';

const fetch = window.fetch;

const defaultOptions = {
  method: "GET",
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    throw error;
  }
};

export const fetchUrl = (
  url,
  options = {},
  search
) => {
  if (search) {
    url += `?${qs.stringify(search)}`;
  }

  return fetch(url, {
    ...defaultOptions,
    ...options,
  }).catch((err) => {
    console.log(err);
  });
};

export const runDownload = async (url) => {
  return fetch(url, {
    ...defaultOptions,
    headers: {},
  })
    .then((response) => checkStatus(response))
    .then((res) => res)
    .catch((err) => {});
};

export const runFunction = async (name, options = {}, search) => {
  const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URI;
  const idToken = await firebase.auth().currentUser.getIdToken();
  console.log(idToken);

  let url = `${baseUrl}/${name}/`;
  if (search) {
    url += `?${qs.stringify(search)}`;
  }

  return fetch(url, {
    ...defaultOptions,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    ...options
  })
    .then(checkStatus)
    .then(res => res.json())
    .catch(err => {});
};
