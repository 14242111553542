import React, { FunctionComponent } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { runDownload } from "../modules/api";
import { withNotification } from "../modules/hoc/NotificationProvider";

type ButtonType = {
  url: string;
  children: any;
  showNotification: Function
};

const AppDownloadButton: FunctionComponent<ButtonType> = ({
  url,
  children,
  showNotification,
  ...props
}: ButtonType) => {

  const download = async () => {
    const data = await runDownload(url);

    if (data) {
      let text = await data.text();
      text = text.replace('<style amp-custom></style>', '');
  
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", "story.html");
  
      element.style.display = "none";
      document.body.appendChild(element);
  
      element.click();
  
      document.body.removeChild(element);
      showNotification('Download completed');
    } else {
      showNotification('Error during download');
    }
  };
  return (
    <Button
      type="text"
      onClick={download}
      {...props}
      icon={<DownloadOutlined />}
    >
      {children}
    </Button>
  );
};

export default withNotification(AppDownloadButton);
