import React from "react";
import * as firebaseui from "firebaseui";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router-dom";

import { googleAuth } from "../config";
import { withAuth } from "../modules/hoc/AuthProvider";
import {
  firebase,
  firestore,
  firestoreServerTimestamp,
} from "../modules/firebase-helper";
import {
  hasAccessAuthority
} from "../modules/access-controller";

const styles = `
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e5a100;
  }
  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .left {
    width: 40%;
    background-color: #f5f5f5;
  }
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .loginContent {
    margin-top: 48px;
  }
  .right {
    background: linear-gradient(-45deg, #f6d62d, #ffa167, #23a6d5, #23d5ab);
    animation: gradient 6s ease infinite;
    width: 60%;
    background-size: 400% 400%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .loginText {
    text-align: center;
    font-weight: 800;
  }
  .appName {
    text-align: center;
    font-weight: 800;
    margin-bottom: 40px;
  }
  .logo {
    display: block;
    margin: 0 auto 48px;
    width: 300px;
  }
  .copyright {
    text-align: center;
    margin-top: 32px;
  }
  .brandText {
    text-align: center;
    color: white;
    font-weight: 600
  }
`;

const uiConfig = {
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      authMethod: "https://accounts.google.com",
      clientId: googleAuth.clientId,
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: ({ user }) => {
      const userRef = firestore.collection("users").doc(user.uid);
      userRef.set(
        {
          lastLoginAt: firestoreServerTimestamp(),
        },
        { merge: true }
      );
      return false;
    },
  },
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
};

const Login = ({
  currentUser,
  ...props
}) => {

  return currentUser ? (
    <Redirect to={`/dashboard`} />
  ) : (
    <main className="main">
      <div className="wrapper">
        <div className="left">
          <div className="login">
            <div className="loginContent">
              <img
                className="logo"
                alt="Logo"
                width={300}
                height={231}
                src={`${process.env.PUBLIC_URL}/assets/images/story.svg`}
              />
              <h4 className="loginText">Sign In to</h4>
              <h1 className="appName">Story Engine</h1>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
                uiCallback={(ui) => ui.disableAutoSignIn()}
              />
              <div className="copyright">
                @ 2020 FourM, inc. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div>
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="Logo"
            />
            <h2 className="brandText">テクノロジーとインテリジェンスで</h2>

            <h2 className="brandText">
              持続可能なメディアビジネスを支援し続ける
            </h2>
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </main>
  );
};

export default withAuth(Login);
