import React, { useState, useEffect } from "react";
import { Radio } from "antd";

import { firestore } from "../../modules/firebase-helper";

const styles = `
  .ant-radio-button-wrapper {
    padding: 0;
    margin: 12px 20px;
    height: 270px;
  }
`;

const Selector = ({
  mediaId,
  options,
  loading,
  defaultValue = "demo:a",
  onChange,
}) => {
  if (loading) {
    return null;
  }
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Radio.Group value={value} onChange={handleChange}>
      {options &&
        options.length > 0 &&
        options.map((option) => (
          <Radio.Button
            key={`${mediaId}:${option}`}
            value={`${mediaId}:${option}`}
          >
            <img
              alt="a"
              width={150}
              height={"100%"}
              src={`${
                process.env.PUBLIC_URL
              }/assets/images/${mediaId}/${option}.png`}
            />
          </Radio.Button>
        ))}
      <Radio.Button value="demo:a">
        <img
          alt="a"
          width={150}
          height={"100%"}
          src={`${process.env.PUBLIC_URL}/assets/images/demo/a.png`}
        />
      </Radio.Button>
      <Radio.Button value="demo:b">
        <img
          alt="a"
          width={150}
          height={"100%"}
          src={`${process.env.PUBLIC_URL}/assets/images/demo/b.png`}
        />
      </Radio.Button>
      <Radio.Button value="demo:c">
        <img
          alt="a"
          width={150}
          height={"100%"}
          src={`${process.env.PUBLIC_URL}/assets/images/demo/c.png`}
        />
      </Radio.Button>
      <Radio.Button value="demo:d">
        <img
          alt="a"
          width={150}
          height={"100%"}
          src={`${process.env.PUBLIC_URL}/assets/images/demo/d.png`}
        />
      </Radio.Button>
      <style jsx>{styles}</style>
    </Radio.Group>
    // <Select defaultValue={value} value={value} style={{ width: 200 }} loading={loading} onChange={handleChange}>
    //   <OptGroup label="Demo">
    //     <Option value="demo:a">demo: 1</Option>
    //     <Option value="demo:b">demo: 2</Option>
    //     <Option value="demo:c">demo: 3</Option>
    //     <Option value="demo:d">demo: 4</Option>
    //   </OptGroup>
    //   {options && options.length > 0 && (
    //     <OptGroup label={mediaId}>
    //       {options.map((option) => (
    //         <Option key={`${mediaId}:${option}`} value={`${mediaId}:${option}`}>{mediaId}: {option}</Option>
    //       ))}
    //     </OptGroup>
    //   )}
    // </Select>
  );
};

const TemplateSelect = ({ mediaId, defaultValue, value, onChange }) => {
  const [loading, toggleLoading] = useState(true);
  const [options, updateOptions] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const snapshot = await firestore.doc(`media/${mediaId}`).get();
      if (snapshot.exists) {
        const data = snapshot.data();
        if (data.templates) {
          updateOptions(data.templates);
        }
      }
      toggleLoading(false);
    };
    getData();
  }, []);

  return (
    <Selector
      options={options}
      mediaId={mediaId}
      value={defaultValue}
      loading={loading}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default TemplateSelect;
