import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { FirestoreProvider } from 'react-firestore';

import { firebase } from "./modules/firebase-helper";
import AuthProvider from './modules/hoc/AuthProvider';
import AppRoutes from './pages/route';

const App = () => (
  <Router>
    <AuthProvider>
      <FirestoreProvider firebase={firebase}>
        <AppRoutes />
      </FirestoreProvider>
    </AuthProvider>
  </Router>
);

export default App;
