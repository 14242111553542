import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';

import MediaList from './MediaList';
import MediaEdit from './MediaEdit';
import StoryList from './StoryList';
import StoryEdit from './StoryEdit';
import AppFrame from '../components/AppLayout';
import { ProtectedRoute } from '../components/ProtectedRoute';

const AppRoutes: FunctionComponent = () => (
    <Switch>
      <AppFrame>
        <ProtectedRoute exact path="/dashboard/media/:mediaId/story/:storyId/edit" component={StoryEdit} />
        <ProtectedRoute exact path="/dashboard/media/:mediaId/story/edit" component={StoryEdit} />
        <ProtectedRoute exact path="/dashboard/media/:mediaId/story" component={StoryList} />
        <ProtectedRoute exact path="/dashboard/media/:mediaId/edit" component={MediaEdit} />
        <ProtectedRoute exact path="/dashboard/media/edit" component={MediaEdit} />
        <ProtectedRoute exact path="/dashboard" component={MediaList} />
      </AppFrame>
    </Switch>
)

export default AppRoutes;