import React, { lazy, Suspense } from "react";
import { Route, Redirect } from "react-router-dom";

import LoadingScreen from "./LoadingScreen";
import RedirectToRoot from "./RedirectToRoot";
import { AuthConsumer } from "../modules/hoc/AuthProvider";


export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => (
  <AuthConsumer>
    {({
      currentUser,
      isLoading: authIsLoading,
      sendVerificationEmail,
      ...context
    }) => {
      if (authIsLoading || typeof authIsLoading === 'undefined') {
        return (
          <LoadingScreen />
        )
      }
      if (currentUser) {
        const { roles = [], media = [] } = currentUser;
        if (roles.indexOf('admin') === -1 && media.length === 0) {
          return <Redirect to="/forbidden" />;
        }

        return (
          <Route
            render={props => (
              <Component currentUser={currentUser} {...context} {...props} />
            )}
            {...rest}
          />
        )
      }
      return (
        <Redirect to="/" />
      )
    }
    }
  </AuthConsumer>
);

export const LazyProtectedRoute = ({ page, emailVerified = true, ...rest }) => {
  const Component = lazy(() => import(`pages/${page}`));

  const LazyComponent = props => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

  return (
    <AuthConsumer>
      {({ currentUser, isLoading: authIsLoading, ...context }) =>
        authIsLoading ? (
          <LoadingScreen />
        ) : currentUser && (currentUser.email_verified || !emailVerified) ? (
          <Route
            render={props => (
              <LazyComponent
                currentUser={currentUser}
                {...context}
                {...props}
              />
            )}
            {...rest}
          />
        ) : (
          <RedirectToRoot />
        )
      }
    </AuthConsumer>
  );
};
