import React, { FunctionComponent } from 'react';
import { Table } from 'antd';

type PropsType = {
  data: Array<any>;
  columns: Array<any>;
}

const AppTable: FunctionComponent<PropsType> = ({
  data,
  columns
}: PropsType) => (
  <Table dataSource={data} columns={columns}  size="middle" />
);

export default AppTable;
