import React, { FunctionComponent } from "react";
import { Typography } from "antd";

import LinkButton from "../components/LinkButton";
import MediaCollection from "../components/firestore/MediaCollection";

const { Title } = Typography;

const style = `
  .page {
    margin: 40px;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .create-button {

  }
`;

const MediaList: FunctionComponent = () => (
  <div className="page">
    <div className="table-header">
      <Title level={2} style={{ marginLeft: 12 }}>
        Media
      </Title>
      <div className="create-button">
        <LinkButton
          path="/dashboard/media/edit"
          buttonName="Create Media"
          size="large"
        />
      </div>
    </div>
    <MediaCollection />
    <style jsx>{style}</style>
  </div>
);

export default MediaList;
