import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

class RedirectToRoot extends Component {
  render() {
    return <Redirect to={'/dashboard'} />;
  }
}

export default withRouter(RedirectToRoot);
