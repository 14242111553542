import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

import AppAvatar from "./AppAvatar";

const { Header, Content } = Layout;

const style = `
  .root {
    height: 100vh;
  }
  .header {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: rgb(140 50 255);
  }
  .app-header-container {
    display: flex;
    justify-content: space-between;
  }
  .app-title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Noto Sans';
  }
  .app-avatar {
    display: flex;
    align-items: center;
  }
  .site-layout-background {
    min-height: 380px;
  }
  .footer {
    text-align: center;
    padding: 12px 36px;
  }
`;

const AppLayout = ({ children }) => (
  <Layout className="root">
    <Header className="header">
      <div className="app-header-container">
        <div className="app-title">
          <Link to={`/`}>
            <img
              style={{ width: 240 }}
              src={`${process.env.PUBLIC_URL}/assets/images/text-logo.svg`}
              alt="Story Engine"
            />
          </Link>
        </div>
        <div className="app-avatar">
          <AppAvatar />
        </div>
      </div>
    </Header>
    <Content
      className="site-layout"
      style={{ marginTop: 64, backgroundColor: '#fff' }}
    >
      <div className="site-layout-background">{children}</div>
    </Content>
    <style jsx>{style}</style>
  </Layout>
);

export default AppLayout;
