import React, { useState } from 'react';
import { Typography, Button } from 'antd';
import {
  useParams
} from "react-router-dom";

import StoryCollection from '../components/firestore/StoryCollection';
import NewStoryForm from '../components/form/NewStoryForm';
import {
  firestore,
  firestoreServerTimestamp,
} from "../modules/firebase-helper";

const { Title } = Typography;

const style = `
  .page {
    margin: 40px;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`

const StoryList = ({
  history
}) => {
  const pathParams = useParams();
  const { mediaId } = pathParams;
  const [drawerVisible, setDrawerVisible] = useState(false);

  const initialValues = {
    urls: [],
    adUnitPath: '',
    isSupportLandscape: false,
    isAutoPaging: false,
    overrideCss: '',
    template: 'demo:a',
    templateId: 'demo',
    templatePrefix: 'a'
  }

  const onFinish = async (values) => {
    const doc = firestore.collection("media").doc(mediaId).collection("story").doc();
    await doc.set({
      name: values['name'],
      createdAt: firestoreServerTimestamp(),
      updatedAt: firestoreServerTimestamp(),
      ...initialValues
    })
    history.push(`/dashboard/media/${mediaId}/story/${doc.id}/edit`);
  };

  return (
    <div className="page">
      <div className="table-header">
        <Title level={2} style={{ marginLeft: 12 }}>Story</Title>
        <div className="create-button">
          <Button
            type="primary"
            size="large"
            onClick={() => setDrawerVisible(true)}
          >
            Create Story
          </Button>
        </div>
      </div>
      <StoryCollection mediaId={mediaId} />
      
      <style jsx>{style}</style>

      <NewStoryForm
        visible={drawerVisible}
        onFinish={onFinish}
        onClose={() => setDrawerVisible(false)}
      />
    </div>
  )
}

export default StoryList;
