import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Redirect, useParams } from "react-router-dom";
import { Row, Col } from "antd";
import compose from "recompose/compose";

import StoryForm from "../components/form/StoryForm";
import AmpStoryPlayer from "../components/story/AmpStoryPlayer";
import { withNotification } from "../modules/hoc/NotificationProvider";
import {
  firestore,
  firestoreServerTimestamp,
} from "../modules/firebase-helper";

const appContainer = `
  .app-container {
    height: calc(100vh - 64px);
    overflow: scroll;
    background-color: #fff;
  }
  .app-container > .left {
    padding: 40px;
    background-color: #fff;
  }
  .amp-story-container {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 15px 5px rgb(0 0 0 / 15%);
    background-color: #fff;
  }
  .ant-input {
    font-size: 16px;
    line-height: 2.2;
  }
  textarea.ant-input {
    font-size: 16px;
    line-height: 2;
  }
  .ant-switch {
    margin-left: 16px;
  }
`;

const StoryEdit = ({ history, showNotification, ...props }) => {
  const pathParams = useParams();
  const { mediaId, storyId } = pathParams;

  const mediaPath = `/media/${mediaId}`;
  const mediaRef = firestore.doc(mediaPath);

  const [mediaDoc, setMediaDoc] = useState(null);

  useEffect(
    () =>
      mediaRef.onSnapshot((docShapshot) => {
        setMediaDoc(docShapshot);
      }),
    [mediaPath]
  );

  const loading = !mediaDoc;
  let media;

  if (mediaDoc) {
    if (!mediaDoc.exists) {
      return <Redirect to={`/dashboard/media`} />;
    }
    media = mediaDoc.data();
  }

  const onFinish = async (value) => {
    const documentPath = `media/${mediaId}/story/${storyId}`;
    const {
      name,
      adUnitPath = "",
      isSupportLandscape = false,
      isAutoPaging = false,
      overrideCss = "",
      textUrls = "",
      template = 'demo:a'

    } = value;
    const templateId = template.split(':')[0]
    const templatePrefix = template.split(':')[1]
    const arrayUrls = textUrls.split('\n');
    if (storyId) {
      firestore
        .doc(documentPath)
        .update({
          urls: arrayUrls,
          textUrls: textUrls,
          name: name,
          adUnitPath: adUnitPath,
          isSupportLandscape: isSupportLandscape,
          isAutoPaging: isAutoPaging,
          updatedAt: firestoreServerTimestamp(),
          template: template,
          templateId: templateId,
          templatePrefix: templatePrefix,
          isCrawling: true,
          overrideCss: overrideCss,
        })
        .then(() => {
          showNotification("Success update");
        });
    } else {
      const docref = firestore
        .collection("media")
        .doc(mediaId)
        .collection("story")
        .doc();
      docref
        .set({
          urls: arrayUrls,
          textUrls: textUrls,
          name: name,
          adUnitPath: adUnitPath,
          isAutoPaging: isAutoPaging,
          isSupportLandscape: isSupportLandscape,
          createdAt: firestoreServerTimestamp(),
          updatedAt: firestoreServerTimestamp(),
          template: template,
          templateId: templateId,
          templatePrefix: templatePrefix,
          isCrawling: true,
          overrideCss: overrideCss,
        })
        .then(() => {
          showNotification("Success create new story");
        });
    }
  };

  return (
    <>
      <Row className="app-container" wrap={true}>
        <Col span={17} className="left">
          {!loading ? (
            <StoryForm
              onFinish={onFinish}
              isPremium={media.grade === "premium"}
            />
          ) : (
            <div />
          )}
        </Col>
        <Col
          span={7}
          style={{ position: "sticky", top: "0", height: "calc(100vh - 64px)" }}
        >
          <div className="amp-story-container">
            <AmpStoryPlayer />
          </div>
        </Col>
      </Row>
      <style jsx>{appContainer}</style>
    </>
  );
};

export default compose(
  withRouter,
  withNotification
)(StoryEdit);
