import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

type LinkButtonType = {
  path: string,
  buttonName: String,
  size?: string
}

const LinkButton: FC<LinkButtonType> = ({
  path,
  buttonName,
  ...props
}: LinkButtonType) => (
  <Button type="primary">
    <Link to={path}>{buttonName}</Link>
  </Button>
);

export default LinkButton;