/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { FirestoreDocument } from "react-firestore";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Skeleton, Button, Menu, Dropdown, Typography } from "antd";
import { ArrowsAltOutlined, DownOutlined } from "@ant-design/icons";
import Iframe from "react-iframe";

import DownloadButton from "../AppDownloadButton";
import { withNotification } from "../../modules/hoc/NotificationProvider";
import { STORY_PREVIEW_LOCATION } from "../../config";

const { Text } = Typography;

const styles = `
  .player {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0%;
  }
  .player iframe {
    width: 100%;
    height: 90%;
    right: 0;
    left: 0;
    border: none;
    margin: 0 auto;
  }
  .header-area {
    margin-bottom: 24px;
    text-align: right;
  }
  .footer-area {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .ohoshi {
    width: 80%;
    height: 80vh;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: none;
    margin: 24px auto;
  }
  .actions {
    width: 100%;
  }
  .actions > button:first-child:not(:last-child) {
    width: 100%;
  }
  .hostingHtml {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    width: 80%;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hostingHtml::-webkit-scrollbar {
    display: none;
  }
  .ant-skeleton-element {
    display: block;
    height: 100%;
  }
  .loading-iframe span {
    margin: 24px auto;
    display: block;
    width: 100%!important;
    height: 100%!important;
    right: 0;
    left: 0;
    border: none;
    margin: 0 auto;
  }
  .ant-dropdown-link {
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px;
  }
`;

const ClipBoard = withNotification(({ hostingHtml, showNotification }) => {
  return (
    <>
      <Text className="hostingHtml" type="secondary">
        {hostingHtml}
      </Text>
      <CopyToClipboard
        text={hostingHtml}
        onCopy={() => showNotification("Copied")}
      >
        <Button>Copy</Button>
      </CopyToClipboard>
    </>
  );
});

const Loading = () => (
  <div style={{ width: "100%", height: "90%" }}>
    <Skeleton.Avatar className="loading-iframe" active={true} shape="square" />
    <style jsx>{styles}</style>
  </div>
);

const AmpStoryPlayer = () => {
  const pathParams = useParams();
  const { mediaId, storyId } = pathParams;

  return (
    <FirestoreDocument
      path={`media/${mediaId}/story/${storyId}`}
      render={({ isLoading, data, snapshot, error }) => {
        if (isLoading) {
          return <Loading />;
        }

        if (error) {
          return <div>Error has occured</div>;
        }

        if (!isLoading && data) {
          if (!data["urls"] || data["urls"].length === 0) {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    flexDirection: 'column',
                    flexWrap: 'wrap'
                  }}
                >
                  <img
                    style={{ width: 240, marginBottom: 16 }}
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/createstory.png`}
                    alt="Story Engine"
                  />
                  URLを入力するとStoriesが自動で生成されます
                </div>
              </>
            );
          }

          return (
            <div className="player">
              <div className="ohoshi">
                <div className="header-area">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <Button type="text" icon={<ArrowsAltOutlined />}>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${STORY_PREVIEW_LOCATION}/media/${mediaId}/story/${storyId}/${
                                data.templateId
                              }/${data.templatePrefix}`}
                            >
                              全画面で見る
                            </a>
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <DownloadButton
                            url={`${STORY_PREVIEW_LOCATION}/media/${mediaId}/story/${storyId}/${
                              data.templateId
                            }/${data.templatePrefix}`}
                          >
                            HTML ダウンロード
                          </DownloadButton>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <DownOutlined />
                      アクション
                    </a>
                  </Dropdown>
                </div>
                {data["isCrawling"] ||
                !data["values"] ||
                data["values"].length === 0 ? (
                  <Loading />
                ) : (
                  <Iframe
                    src={`${
                      process.env.REACT_APP_STORY_PREVIEW_LOCATION
                    }/media/${mediaId}/story/${storyId}/${data.templateId}/${
                      data.templatePrefix
                    }`}
                  />
                )}
                <div className="footer-area">
                  <ClipBoard
                    hostingHtml={`${
                      process.env.REACT_APP_STORY_PREVIEW_LOCATION
                    }/media/${mediaId}/story/${storyId}/${data.templateId}/${
                      data.templatePrefix
                    }`}
                  />
                </div>
              </div>
              <style jsx>{styles}</style>
            </div>
          );
        }
      }}
    />
  );
};

export default AmpStoryPlayer;
