import React from "react";
import { Drawer, Form, Button, Input } from "antd";

import SubmitButton from "../AppSubmitButton";

const FormItem = Form.Item;

const NewPostForm = ({ onClose, onFinish, visible }) => {
  const [form] = Form.useForm();

  return (
    <Drawer
      title="Create a new post"
      width={360}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={visible}
    >
      <Form form={form} onFinish={onFinish}>
        <FormItem
          label="タイトル"
          name="name"
          rules={[{ required: true, message: "Please input Story name" }]}
        >
          <Input placeholder="Input Your Story Name" />
        </FormItem>
        <div
          style={{
            width: "100%",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <SubmitButton />
        </div>
      </Form>
    </Drawer>
  );
};

export default NewPostForm;
