import React from "react";

import { Tooltip, Button } from "antd";
import { InfoOutlined } from "@ant-design/icons";

type propsType = {
  text: String;
};

const TooltipButton = ({ text }: propsType) => (
  <Tooltip placement="right" title={text}>
    <Button shape="circle" size="small" icon={<InfoOutlined />} />
  </Tooltip>
);

export default TooltipButton;
