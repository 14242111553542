import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppRoute from './appRoute';
import LazyRoute from  '../components/LazyRoute';
import { ProtectedRoute } from '../components/ProtectedRoute';
import Login from './Login';
import Accept from './Accept';

const AppRoutes = () => (
    <Switch>
      <ProtectedRoute path="/dashboard" component={AppRoute} />
      <LazyRoute path="/invitation" component={Accept} />
      <LazyRoute exact path="/forbidden" page="Forbidden" />
      <LazyRoute exact path="/createuser" page="CreateUser" />
      <Route exact path="/" component={Login} />
    </Switch>
)

export default AppRoutes;
