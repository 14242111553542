import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";

import AppTable from "../AppTable";
import { formatDate } from "../../modules/utils";

const storyColumns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text, record) => (
      <Link to={`story/${record.id}/edit`} key={record.id}>
        {text}
      </Link>
    ),
  },
  {
    title: "ページ数",
    dataIndex: "urls",
    render: (urls) => <div>{urls ? urls.length : 0}</div>,
  },
  {
    title: "最終更新日時",
    dataIndex: "updatedAt",
    render: (updatedAt) =>
      updatedAt && <div>{formatDate(updatedAt.toDate())}</div>,
  },
];

const StoryCollection = ({ mediaId }) => {
  return (
    <FirestoreCollection
      path={`media/${mediaId}/story`}
      sort="createdAt:desc"
      render={({ isLoading, data, snapshot, error }) => (
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <AppTable data={data} columns={storyColumns} />
          )}
        </div>
      )}
    />
  );
};

export default StoryCollection;
