import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal } from 'antd';
import { FirestoreDocument } from 'react-firestore';
import qs from 'query-string';

import { withAuth } from '../modules/hoc/AuthProvider';
import { firestore } from '../modules/firebase-helper';
import { runFunction } from '../modules/api';

const Invitation = ({
  currentUser,
  location,
  history
}) => {
  if (!currentUser) {
    return <Redirect to="/forbidden" /> 
  }

  const { projectId } = qs.parse(location.search);
  const invitationRef = `mediaInvitations/${projectId}__${currentUser.email}`;

  const [ accepted, setAccepted] = useState(false);
  const [ declined, setDeclined] = useState(false);

  const accept = () => {
    setAccepted(true);
    runFunction('invitationAcceptInvitationOnWrite', {}, {
      media: projectId
    }).then(() => {
      window.location.href = `/dashboard`;
    })
  }

  const decline = () => {
    setDeclined(true);
    firestore
      .doc(invitationRef)
      .delete()
      .then(() => {
        window.location.href = '/';
      })
  }

  return (
    <FirestoreDocument
    path={invitationRef}
    render={({ isLoading, data, snapshot }) =>
      isLoading ? (
        <div />
      ) : snapshot && snapshot.exists ? (
        <React.Fragment>
          <Modal visible={true} onOk={accept} confirmLoading={accepted}>
            <p>{`${currentUser.email}さま`}</p>
            <p>{`Story Engineへのご参加ありがとうございます`}</p>
          </Modal>
        </React.Fragment>
      ) : (
        <Redirect to="/" />
      )
    }
  />
  )
}

export default withAuth(Invitation);
