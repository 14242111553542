import React, { useState } from "react";
import { Avatar, Popover, Button } from "antd";

import { withAuth } from "../modules/hoc/AuthProvider";

const style = `
  .avatar-container {
    overflow: hidden;
    display: flex;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 24px;
    padding: 0 12px 0 0;
    color: #fff;
    font-weight: 600;
  }
  .avatar-image {
    display: flex;
    align-items: center;
  }
  .avatar-name {
    display: inline-block;
    float: left;
    line-height: 32px;
    margin-left: 10px;
    font-size: 14px;
  }
`;

const AppAvatar = ({ currentUser, logout }) => {
  const [isVisible, toggle] = useState(false);

  if (!currentUser) {
    return null;
  }

  const handleLogout = () => {
    logout();
  };

  return (
    <Popover
      trigger="click"
      visible={isVisible}
      onVisibleChange={(visible) => toggle(visible)}
      content={
        <Button type="text" onClick={() => handleLogout()}>
          Logout
        </Button>
      }
    >
      <div className="avatar-container">
        <Avatar
          size="default"
          src={currentUser.picture}
          style={{ display: "flex", alignItems: "center" }}
        />
        <div className="avatar-name">{currentUser.name}</div>
        <style jsx>{style}</style>
      </div>
    </Popover>
  );
};

export default withAuth(AppAvatar);
