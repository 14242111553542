import React, { Component, createContext } from "react";

import { auth } from "../firebase-helper";

const AuthContext = createContext({});
const { Provider, Consumer: AuthConsumer } = AuthContext;

class AuthProvider extends Component {
  componentDidMount() {
    this.refreshAuth();
  }

  unregisterAuthObserver = null;

  refreshAuth() {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
    }

    this.unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.setState({
          isLoading: false,
        });
        return;
      }

      const idToken = await user.getIdTokenResult(true);
      this.setState({
        currentUser: {
          ...idToken.claims,
          displayName: user.displayName,
        },
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  destroySession() {
    this.setState({
      currentUser: null,
    });
  }

  logout() {
    return auth
      .signOut()
      .then(this.destroySession.bind(this))
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          logout: this.logout.bind(this),
          refreshAuth: this.refreshAuth.bind(this),
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const withAuth = (
  WrappedComponent
) => {
  return (props) => (
    <AuthConsumer>
      {({ isLoading, ...rest })=> {
        return isLoading ? <div /> : <WrappedComponent isLoading={isLoading} {...props} {...rest} />;
      }}
    </AuthConsumer>
  )
}

export default AuthProvider;

export { AuthContext, AuthConsumer, withAuth };
