type firebaseConfigType = {
  apiKey?: String;
  authDomain?: String;
  databaseURL?: String;
  projectId?: String;
  storageBucket?: String;
  messagingSenderId?: String;
};

export const firesbase: firebaseConfigType = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_apiKey,
};

type GoogleAuthTypes = {
  clientId?: string
}

export const googleAuth: GoogleAuthTypes = {
  clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
};

export const BASE_URL: string | undefined = process.env.REACT_APP_FIREBASE_authDomain;

export const STORY_PREVIEW_LOCATION: string | undefined = process.env.REACT_APP_STORY_PREVIEW_LOCATION;
