import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

const firestore: firebase.firestore.Firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

const auth: firebase.auth.Auth = firebase.auth();

const firestoreServerTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();

const storage: firebase.storage.Storage = firebase.storage();

export { auth, firebase, firestore, firestoreServerTimestamp, storage };
